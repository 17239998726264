.appbarcolor {
  background-color: #0070bb !important;
}
.signupheader {
  margin-left: 5% !important;
  margin-right: 5% !important;
}
.signupheader1 {
  margin-left: 5% !important;
  margin-right: 5% !important;
}
.logoformatting {
  font-family: century;
  color: black !important;
  position: relative !important;
  height: 70px !important;
}
.image_hader {
  position: absolute !important;
  height: 75px !important;
  width: 118px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.buttonformatting {
  background-color: #fec601 !important;
  color: black !important;
  font-family: century !important;
  width: 50% !important;
  text-transform: capitalize !important;
}

.dropdownbutton {
  height: 35px;
  margin-top: 5%;
  /* background-color: #fec601 !important; */
}
.dropdownbutton1 {
  height: 35px;
  margin-top: 5%;
  background-color: #fec601 !important;
}

.headerbutton_padding {
  padding-top: 10%;
}
.icon_color {
  color: white !important;
  font-size: 15px;
}

.iconsMaindiv {
  justify-content: space-around;
  display: flex;
  padding-top: 10px;
}

.iconsMaindiv2 {
  justify-content: space-around;
  display: flex;
  padding-top: 10px;
}

.insideicondiv {
  justify-content: space-around;
  /* display: flex; */
  align-items: center;
  padding-top: 8px;
  cursor: pointer;
}
.desktop_view_button {
  display: none;
}

.dropbtn {
  /* background-color: #04aa6d; */
  color: white;
  /* padding: 16px; */
  font-size: 16px;
  border: none;
}

/* .dropdown {
  position: relative;
  display: inline-block;
} */

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffff;
  min-width: 160px;
  border: 1px solid lightgray;
  border-radius: 4px;
  z-index: 1;
  margin-left: 74%;
  margin-top: -15px;
}

.dropdown-content .drop_down_links {
  color: black;
  padding: 2px 2px;
  text-decoration: none;
  display: block;
}

.dropdown-content .drop_down_links:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.margin {
  margin-bottom: 1rem;
}
.Gap_from_bottom_Menu_Spacing {
  margin-bottom: 1rem;
  justify-content: space-around;
  display: flex;
  align-items: center;
  padding-top: 8px;
  cursor: pointer;
}
.logout_Pointer_cursor {
  cursor: pointer !important;
}

.logout_Pointer_cursor .MuiPaper-elevation1 {
  box-shadow: none !important;
}
.logout_Pointer_cursor .MuiAccordionSummary-root {
  padding: 0px 0px !important;
  min-height: 40px !important;
}

.logout_Pointer_cursor .MuiPaper-root {
  color: #818181 !important;
}

.logout_Pointer_cursor .MuiIconButton-root {
  padding: 0px !important;
}
.logout_Pointer_cursor .MuiAccordionSummary-content {
  margin: 8px 0px !important;
}
.padding_report_admission {
  padding: 8px 8px 8px 0px !important;
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .image_hader {
    height: 60px !important;
    width: 80px !important;
  }
  .appbarcolor{
    height: 10%;
  }

  .icon_color {
    color: white !important;
    font-size: 12px;
    cursor: pointer;
  }
  .dropdownbutton {
    height: 25px;
    margin-top: 5%;
    font-size: 10px !important;
  }

  .headerbutton_padding {
    padding-top: 30%;
  }
  .desktop_view_button {
    display: block;
  }
}

.arrow_box:after {
  z-index: 999999;
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  /* -webkit-transform: translateX(-60%); */
  pointer-events: none;
  border-color: rgba(204, 204, 204, 0);
  border-bottom-color: white;
  border-width: 7px;
  margin-left: -8px;
}

.arrow_box {
  position: absolute;
  /* background: coral; */
  width: auto;
  height: auto;
  -webkit-transform: translateX(-38%);
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}
.mobile_Burger_Menu {
  display: none;
}
.dropdown_main_div {
  margin-left: -40px;
}
.userName_mobileView_color {
  font-size: 20px;
  color: #0070bb;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1120;
  top: 0;
  left: 0;
  background-color: white;
  border-right: 1px solid lightgray;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidenav span {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 15px;
  color: #818181;
  display: block;
  transition: 0.3s;
  text-align: left !important;
}

.sidenav span:hover {
  color: #f1f1f1;
}
.cross_icon_style {
  text-align: right !important;
  color: black;
  margin-right: 5px;
  font-size: 20px;
  cursor: pointer;
}
.dropdown_Mobile_view_Content {
  display: none;
  position: absolute;
  background-color: #ffff;
  min-width: 160px;
  border: 1px solid lightgray;
  border-radius: 4px;
  z-index: 1;
  margin-left: 14%;
  margin-top: -15px;
}

.dropdown_Mobile_view_Content .drop_down_links {
  color: black;
  padding: 2px 2px;
  text-decoration: none;
  display: block;
}

.dropdown_Mobile_view_Content .drop_down_links:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown_Mobile_view_Content {
  display: block;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 5px;
  }
  .sidenav span {
    font-size: 18px;
  }
}
@media screen and (max-width: 450px) {
  .amininstitution_padding {
    padding-right: 0% !important;
  }
}
@media only screen and (min-width: 250px) and (max-width: 950px) {
  .iconsMaindiv2 {
    display: none;
  }
  .mobile_Burger_Menu {
    display: block;
  }
}
.images_background_logo {
  background-color: white !important;
}
.amininstitution_padding {
  padding-right: 14%;
}
