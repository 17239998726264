.card_invoicedetails_border {
  border: 1px solid gainsboro !important;
  border-radius: 4px !important;
}
.table_Padding_invoice_list {
  padding: 7px !important;
}
.cursor_for_print {
  cursor: pointer;
}
