.List_heading_formatting {
  font-size: 20px;
}

.inputBorderLine {
  border-bottom: 1px solid;
}
.textWrapper {
  height: 35px;
  margin: 7px;
  border: 1px solid #ced4da;
  border-color: #ced4da;
  padding: 5px;
  border-radius: 3px;
  justify-content: center;
}
.textWrapper > p {
  margin: 0px;
}
.imageCursor {
  cursor: pointer;
}
.paddingContainer {
  padding: 10px;
}
.pText {
  margin: 0px;
  font-weight: 500;
}
.box {
  min-height: 80px;
  margin-bottom: 25px;
}

.cardPadding {
  padding-top: 2%;
  padding-bottom: 2%;
}
.onlyMargin {
  margin-top: 26px !important;
}
.compose_flex_end {
  display: flex;
}
