.btn_font_teacher_review {
  font-size: 9px !important;
}
.addSectionfont {
  font-size: 16px;
}
.editor_height {
  max-height: calc(100vh - 100px) !important;
}
.RichTextEditor__editor___1QqIU {
  height: 30vh !important;
}
.assignment_view_padding_head {
  padding: 6px !important;
}
.assugment_border {
  border-width: 2px !important;
  border-color: darkgray;
  padding-bottom: -1px;
  margin-bottom: 5px;
  border-style: solid;
}
.Outside_border_updateAssignment {
  border: 1px solid lightgray;
}
.MuiDialog-paperScrollPaper {
  border: 2px solid blue !important;
}
.MuiDialog-paper {
  border-radius: 12px !important;
}
