.Main_Grid_For_MENUS {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.cards_Home_menu_margin {
  margin-right: 3%;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .Main_Grid_For_MENUS {
    width: 100%;
    height: auto;
    display: block !important;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .cards_Home_menu_margin {
    margin-right: 0%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 800px) {
  .Main_Grid_For_MENUS {
    width: 100%;
    height: auto;
    display: block !important;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .cards_Home_menu_margin {
    margin-right: 0%;
  }
}
@media only screen and (min-width: 801px) and (max-width: 960px) {
  .Main_Grid_For_MENUS {
    width: 100%;
    height: auto;
    display: flex !important;
    flex-wrap: initial !important;
    box-sizing: border-box;
  }
  .cards_Home_menu_margin {
    margin-right: 3%;
  }
}

.total_count_floatright {
  padding-bottom: 20px;
}
.total_count_flex {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.count_display_lebel {
  font-size: 14px;
  font-weight: 500;
}
.total_count_border_radious {
  border: 5px solid #0070bb;
  border-radius: 50%;
  line-height: 45px;
  text-align: center;
  height: 70px;
  width: 70px;
}
.total_count_border_radious p {
  font-size: 13px;
  padding: 5px;
}
/* new css */
.home_menu_card_center {
  text-align: center;
  justify-content: center;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
}
.home_menu_size_of_icons {
  font-size: 30px;
  color: #0070bb;
}
.home_menu_whiteLine {
  height: 2px;
  width: 20px;
  background: #0070bb;
  margin: auto;
}
.home_menu_card_center p {
  color: #0070bb;
  margin-bottom: 0px !important;
  font-size: 12px;
}
.mangeinsitiute_main_grid {
  display: flex;
  justify-content: center;
}
/* no section dailog css */
.nosectionParaPadding {
  padding: 0px 10%;
}
