.paymentdue_color {
  color: #e91e63 !important;
}
.MuiTablePagination-root {
  overflow: hidden !important;
}
.paddding_form_top {
  padding-top: 40px !important;
}
#parent_homepage_table {
  min-width: 530px !important;
}

.font_size_Parent_class_data {
  font-size: 11px !important;
  padding: 10px !important;
}
.table_header__parent_padding {
  padding: 10px !important;
}
.table_Parent_home_padding {
  padding: 12px !important;
}
.font_size_Parent_stauts {
  font-size: 13px !important;
}
.balanceCard {
  min-height: 444px !important;
  max-height: 250px !important;
  overflow: hidden;
}
.balanceCard::-webkit-scrollbar{
  scrollbar-width: 7px;
}
