body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.whiteLine {
  height: 2px;
  width: 20px;
  background: #fff;
  margin: auto;
}
.day-cell {
  min-width: 144px !important;
  min-height: 50px !important;
  height: 50px !important;
}
.day-cell > div {
  height: 50px !important;
}
.day-cell > div > div {
  height: 0px !important;
}
.tab__link {
  color: #0070bb;
  padding-right: 4;
  cursor: pointer;
}
p {
  margin-bottom: 0px !important;
}
button:focus {
  outline: none !important;
}
.mouse__pointer {
  cursor: pointer !important;
}
.action__icons {
  color: #0070bb;
  cursor: pointer;
  font-size: 14px;
}
.email_attachement {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  background-color: #fcfcfc;
}
.email_attachment_text {
  padding: 10px;
  color: #00acff;
}
.common_button {
  margin-top: 10px;
  background-color: #0070ba !important;
  color: #fff !important;
  width: 50%;
}
