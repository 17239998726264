.admin_Study_inner_padding {
  padding: 10px;
}
.view_left_classbold {
  font-weight: 500;
}
.view_left_subject_add {
  padding-left: 7%;
}
.view_left_flex {
  display: flex;
  justify-content: space-between;
}

.view_left_subject_add_topic {
  padding-left: 10%;
}
.sub_topic_color {
  color: #0070bb;
  cursor: pointer;
}
.sub_topic_color:hover {
  border-bottom: 1px solid #0070bb;
  width: auto;
}
.plus_icon_color {
  color: #0070bb;
  padding-left: 5%;
}
.add_plus_color {
  color: #0070bb;
}
.right_penal_card_height {
  min-height: 70vh !important;
  max-height: 100vh !important;
  border-left: 2px solid grey !important;
  border-radius: 0px !important;
  background-color: #f2f4f4 !important;
}
.view_study_left_height {
  max-height: 70vh !important;
  min-height: 70vh !important;
  overflow: auto !important;
  border-radius: 0px !important;
  background-color: #f2f4f4 !important;
}
.right_penal_flex {
  display: flex;
  justify-content: space-between;
}
.sub_topic_background_color {
  background-color: white !important;
}
.right_penal_flex_icon {
  display: flex;
  padding-top: 5px;
  padding-left: 2%;
}
.right_penal_flex_icon i {
  padding: 0px 5px;
  /* color: #0070bb; */
}
.react_video_width {
  width: 100% !important;
}
.right_penal_icon_paddong_top {
  padding-top: 12%;
}
.view_right_class_header {
  color: gray;
}
.page_header_study_penal {
  color: black;
  font-weight: bold;
}
.view_right_tpoic_header {
  font-size: 20px;
}
.tpoic_add_flex {
  display: flex;
}
.edit_topic_icon_color {
  /* color: #0070bb; */
  padding-left: 2%;
}
