.e-custom .e-frame {
  border-radius: 100%;
}

.e-checkicon.e-checkbox-wrapper .e-frame.e-check::before {
  content: "\e703";
}

.e-checkicon.e-checkbox-wrapper .e-check {
  font-size: 8px;
}

.e-checkicon.e-checkbox-wrapper .e-frame.e-check {
  background-color: white;
  border-color: grey;
  color: grey;
}

.e-checkicon.e-checkbox-wrapper:hover .e-frame.e-check {
  background-color: white;
  border-color: grey;
  color: grey;
}

.e-checkicon.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check {
  background-color: white;
  border-color: grey;
  box-shadow: none;
  color: grey;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: #0070bb !important;
  border-color: transparent;
  color: #fff;
}
.attendance_cell_width {
  width: 4px !important;
  border-left: 1px solid gainsboro !important;
  border-right: 1px solid gainsboro !important;
  background-color: #ae3450 !important;
}
.attendance_cell_Present {
  width: 4px !important;
  border-left: 1px solid gainsboro !important;
  border-right: 1px solid gainsboro !important;
  background-color: #50ae34 !important;
}
.absentCirlce {
  margin-top: 7px;
  height: 12px;
  width: 12px;
  background-color: #ae3450;
  border-radius: 12px;
}

.presentCircle {
  margin-top: 7px;
  height: 12px;
  width: 12px;
  background-color: #50ae34;
  border-radius: 12px;
}
.custom-table-cell .MuiTableCell-root {
  padding: 5px !important;
}
.MuiTableCell-root .attandance {
  padding: 5px !important;
}
