.pending{
    background-color: #fec601 !important;
    color:white !important;
    width:70% !important;
    text-transform: capitalize !important;
}
.Completed{
    background-color: #97bf47 !important;
    color:white !important;
    width:70% !important;
    text-transform: capitalize !important;
}
.coming{
    background-color: #e91e63 !important;
    color:white !important;
    width:70% !important;
    text-transform: capitalize !important;
}
.result_view{
    color:gray !important;
}