.addmanageuserfont {
  font-style: 7px !important;
  color: #0070bb !important;
  cursor: pointer;
}
.inputMarginrightside {
  margin-right: 10% !important;
}
.dailog_border {
  border-radius: 16px !important;
}
.card_content_input_icon {
  display: flex;
}
.id_card_div {
  width: 100%;
  height: 20vh;
  border: 1px solid black;
  margin-left: 10%;
  margin-right: 10%;
}
.photo_id_card {
  width: 100%;
  height: 90px;
  border: 1px solid black;
  background-color: lightgray;
  /* margin-top: 25%;

  margin-left: 5%; */
}
.ID_card_heading {
  font-size: 12px;
  color: gray;
}
.generate_id_data {
  font-size: 12px;
}
.div_id_card_content_margin {
  margin-top: 20%;
  margin-bottom: 5%;
  margin-left: 2%;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent !important;
  opacity: 1;
}
@media only screen and (min-width: 300px) and (max-width: 650px) {
  .inputMarginrightside {
    margin-right: 1% !important;
  }
  .button_size {
    font-size: 13px !important;
  }

  .resetHeading_font {
    font-size: 28px;
  }
  .id_card_div {
    width: 100%;
    height: 20vh;
    border: 1px solid black;
    margin-left: 2%;
    margin-right: 4%;
  }
  .photo_id_card {
    width: 100%;
    height: 90px;
    border: 1px solid black;
    background-color: lightgray;
    /* margin-top: 35%;
    margin-left: 2%; */
  }
  .ID_card_heading {
    font-size: 10px;
    color: gray;
  }
  .generate_id_data {
    font-size: 10px;
  }
  .div_id_card_content_margin {
    margin-top: 25%;
    margin-bottom: 5%;
    margin-left: 2%;
  }
}
.genrate_icad_padding {
  padding: 25px 10px;
}
.manageuser_dailog_title {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}
.idcard_button_center {
  justify-content: center !important;
}
.genrate_idcard_button_width {
  text-decoration: none !important;
  outline: none !important;
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
}
.id_card_border_padding {
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
}
.photo_id_card_height {
  width: 90px;
  height: 90px;
  border: 1px solid black;
  background-color: lightgray;
}
.idcard_school_font {
  font-size: 15px;
  margin-bottom: 0px !important;
}
.idcard_address_font {
  font-size: 12px;
  margin-bottom: 0px !important;
}
.usr_data_flex {
  display: flex;
  justify-content: space-around;
}
.addressText {
  padding-left: 15px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.4px;
}
.schoolNameText {
  padding-left: 15px;
  letter-spacing: 0.15px;
  line-height: 25px;
  padding-bottom: 2px;
}
.student_name {
  font-size: 13px;
  font-weight: 600;
}
.idCardImage {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  background: grey;
}
