body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.App {
  padding-left: 3%;
  padding-right: 3%;
}
.bg-class {
  background-image: url("./assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 451px;
}
.card-styling {
  margin-top: -6%;
}
.card-layout {
  border-radius: 20px !important;
  height: 450px;
}
.pending {
  margin-bottom: 0;
  font-weight: 600;
  color: #eb2f6a;
  padding-top: 4px;
  padding-bottom: 0px;
  padding-left: 10px;
  padding-right: 10px;
}
.dueDate {
  margin-bottom: 0;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  color: #6c8299;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.has-search .form-control {
  padding-left: 2.375rem;
}
/* .search-input{
  outline: none !important;
  border: none !important;
  text-decoration: none !important;
  width: 60% !important;
  height: 50px !important;
  border-radius: 27px !important;
  background-color: #FFE348 !important;
  margin-bottom: 2%;
}
.form-control:focus {
  box-shadow: none !important;
} */
/* ankur task */
.search-input1 {
  outline: none !important;
  border: none !important;
  text-decoration: none !important;
  width: 95% !important;
  height: 50px !important;
  border-radius: 27px !important;
  background-color: #ffe348 !important;
  margin-bottom: 2%;
}
.form-control:focus {
  box-shadow: none !important;
}
.all-subject {
  height: 50px !important;
  background-color: transparent !important;
  border: 1px solid #596878 !important;
}
.create-new {
  width: 95%;
  height: 50px !important;
  border-radius: 27px;
  border: 1px solid #adb0b3 !important;
  outline: none !important;
  background-color: white !important;
}
.all-subject1 {
  height: 50px !important;
  background-color: transparent !important;
  border: 1px solid #596878 !important;
  width: 90% !important;
}

.main-module-class {
  margin-top: -350px;
  padding-left: 5%;
  padding-right: 5%;
}
.cursor {
  cursor: pointer !important;
}
.actionStyle {
  font-size: 14px;
  color: "#0070bb";
  padding-left: 4px;
  padding-right: 4px;
}

.year-picker {
  width: 100% !important;

  height: 35px !important;
}
.year-input {
  cursor: pointer !important;
}

.docimage {
  cursor: pointer;
}
.UploadDocImagewidth {
  width: 925px;
  height: auto;
}
.outside_border_for_content {
  border: 1px solid lightgray !important;
  width: 100%;
  height: auto;
  border-radius: 4px;
}
@media only screen and (min-width: 320px) and (max-width: 650px) {
  .UploadDocImagewidth {
    width: 258px;
    height: auto;
  }
}
.notification {
  box-sizing: none !important ;
  padding: 0px !important;
  color: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
  opacity: none !important;
  margin-top: 55px !important ;
}
.full_Size_Box {
  padding: 15px;
}
.card__height {
  height: 300px;
  overflow-y: auto;
  margin-bottom: 25px;
}
.Current_Session_card {
  font-size: 15px !important;
  color: black !important;
  background-color: #0070bb !important;
  width: 196.5px;
  font-weight: bold;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.nofilechoosenhide {
  color: transparent !important;
}
.btn_colr_and_widthset {
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
  outline: none !important;
  text-decoration: none !important;
}
#js-licensing {
  display: none !important;
}
