.createinvoiceflex {
  display: flex;
  justify-content: space-between;
}
.invoice_btn_width {
  width: 38%;
}
.margin_from_both_contact1 {
  margin-left: 5%;
  margin-right: 5%;
  padding-bottom: 3%;
  overflow: hidden;
}
.iconstoaddfee {
  color: #0070bb !important;
  font-size: 20px;
}
.card_boder {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  margin-top: 2%;
}
.addButtonwidth {
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
  float: right !important;
  width: 10%;
  margin-bottom: 1% !important;
}
.addButtonwidth1 {
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
  width: 10%;
  margin-bottom: 1% !important;
}
.main_button_div2 {
  display: flex;
  float: right;
}
.btn_add {
  margin-right: 3%;
}
.text_width {
  width: 500px !important;
}
.button_width {
  width: 100% !important;
}
/* .btn_font{
  font-size: 10px !important;
} */
.button_main_flex {
  display: flex !important;
}

.table_header {
  color: black !important;
  font-weight: bold !important;
  background-color: white !important;
}
.paddingone{
  padding: 1px !important;
}
.small-font td {
  font-size: 14px;
}

.Pending {
  color: pink;
}
.Upcoming {
  color: orange;
}
.AlreadyPaid {
  color: greenyellow;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #0070bb !important;
}
.addPointer {
  cursor: pointer;
}
.inputMarginright1 {
  margin-right: 5%;
}
.btn_font_size_invoice {
  font-size: 11px !important;
}
.dailog_feesubtype_setup {
  width: 80vh;
  margin-bottom: 5%;
}
.cross_font_size {
  font-size: 20px;
}
.tableborder_viewinvoice {
  border: 1px solid gray;
}
@media only screen and (min-width: 300px) and (max-width: 650px) {
  .addButtonwidth {
    float: left !important;
    width: 10%;
  }
  .main_button_div2 {
    display: flex;
    float: center !important;
  }
  .text_width {
    width: 200px !important;
  }

  .invoice_btn_width {
    width: auto;
  }
  .btn_font_size_invoice {
    font-size: 8px !important;
  }
  .dailog_feesubtype_setup {
    width: auto;
    margin-bottom: 5%;
  }

  .cross_font_size {
    font-size: 12px;
  }
}
.teacher_review_button_flex {
  display: flex;
  float: right;
  margin-top: 10px;
}
