.content_justfiy {
  text-align: justify;
}

.transition_onHover:hover {
  transform: scale(0.999) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.notice_text_tranformation {
  text-transform: capitalize !important;
}

