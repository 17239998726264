.file_back_color {
  background-color: lightgray;
  cursor: pointer;
}
.attachList {
  display: flex;
}
.text_filed_heading {
  font-weight: 600;
}
.dailog_width_review_leave {
  width: 50vh;
  margin-bottom: 5%;
  text-align: center;
}
.cut_dailog_icon {
  text-align: right;
  cursor: pointer;
}
.data_content {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 300px) and (max-width: 650px) {
  .attachList {
    display: block;
  }
  .dailog_width_review_leave {
    width: 46vh;
    margin-bottom: 5%;
    text-align: center;
  }
  .leave_content {
    font-size: 9px;
  }
  .leave_content_main {
    font-size: 12px;
  }
}
