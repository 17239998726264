.getStartSection {
  padding: 10px 0px;
  text-align: center;
  padding: 10px 10% 0px 10%;
}

.getStartSection .stepvalue {
  font-weight: 700;
  text-align: left;
}

.getStartSection .stepvaluepara {
  font-weight: 500;
  text-align: left;
}
.getStartSection .imagelarge {
  width: 100%;
  padding-top: 10px;
}

.getStartedmarginPadding {
  margin: 0% 3%;
  padding: 8% 0%;
  height: 95vh;
}
.leftcardPostionfixed {
  position: fixed;
  width: 20%;
}
.leftCardTitle {
  border-left: 2px solid;
  padding-left: 10px;
  font-weight: 600;
  margin: 10px 5px;
  cursor: pointer;
}
.leftCardTitle p {
  text-decoration: none;
  outline: none;
  color: black;
}
.leftCardTitleActive {
  border-left: 3px solid #0070bb;
  padding-left: 10px;
  font-weight: 600;
  margin: 10px 5px;
  cursor: pointer;
}
.leftCardTitleActive p {
  text-decoration: none;
  outline: none;
  color: #0070bb;
  font-weight: bold;
}

.cardHeightScroll {
  height: 80vh;
  overflow-y: auto !important;
}
.getStartRightHeading {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}
.getClickColor {
  color: #0070bb;
  cursor: pointer;
}
