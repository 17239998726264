.attendance_text_color {
  color: #0070bb;
}
.img_formatting {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.margin_map {
  padding-left: 3%;
  padding-right: 3%;
}
#time-picker {
  height: 0.1876em !important;
}
.react-html5-camera-photo > img,
.react-html5-camera-photo > video {
  width: 445px !important;
}
.routeopen_text_color {
  color: blue !important;
  cursor: pointer;
}
.create_route_listing_padding {
  padding: 7.2px !important;
  text-transform: capitalize;
}
.marginTop {
  margin-top: 25px !important;
}
.manage_route_flex {
  display: flex;
  justify-content: space-around;
}
.radioContainer {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
}
.mark_attence_button {
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
  outline: none !important;
  text-decoration: none !important;
}
