.e-custom .e-frame {
    border-radius: 100%;
  }
  
  .e-checkicon.e-checkbox-wrapper .e-frame.e-check::before {
      content: '\e703';
  }
  
  .e-checkicon.e-checkbox-wrapper .e-check {
      font-size: 8px;
  }
  
  .e-checkicon.e-checkbox-wrapper .e-frame.e-check {
      background-color: white;
      border-color: grey;
      color: grey;
  }
  
  .e-checkicon.e-checkbox-wrapper:hover .e-frame.e-check {
      background-color: white;
      border-color: grey;
      color: grey;
  }
  
  .e-checkicon.e-checkbox-wrapper .e-checkbox:focus + .e-frame.e-check {
      background-color: white;
      border-color: grey;
      box-shadow: none;
      color: grey;
  }

  .e-checkbox-wrapper .e-frame.e-check, .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: #0070bb !important;
    border-color: transparent;
    color: #fff;
}
.attendance_width{
    width:5px !important;
    border-left: 1px solid gainsboro !important;
    border-right: 1px solid gainsboro !important;
    /* background-color: red !important; */
}


.greencolorDot {
    margin-top: 10px;
    height: 15px;
    width: 15px;
    background-color: rgb(184, 65, 65);
    border-radius: 50%;
    display: inline-block;
  }
  
  .orangecolorDot {
    margin-top: 10px;
    height: 15px;
    width: 15px;
    background-color: rgb(185, 130, 28);
    border-radius: 50%;
    display: inline-block;
  }