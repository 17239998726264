.submit_button_right_set {
  text-align: right;
  margin: 10px 0px;
}
.submit_button_right_set Button {
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
  outline: none !important;
  text-decoration: none !important;
}
.btn_center {
  display: flex;
  justify-content: center;
}
