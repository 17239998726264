.class_overview_heading {
  display: flex;
  justify-content: space-around;
}
.over_view_padding_card {
  padding: 10px !important;
  margin: 10px 0px !important;
}
.class_attendence_Heading {
  font-weight: 500;
}
