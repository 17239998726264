.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #0070bb;
  color: white;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
.autocomplete_border {
  border: 1px solid gray;
}
.autocomplete_border input {
  border: none !important;
}
.autocomplete_border .MuiChip-outlined {
  margin: 2px !important;
}
.auto_flex_input {
  display: inline-flex;
}
.common_notifctaion_active {
  border-bottom: 1px solid;
  font-weight: bold;
}
