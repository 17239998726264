.Footer_main_div{
    width:100%;
    height:30px;
    text-align: center;
}
.Footer_content_formatting{
    text-decoration: none !important;
    cursor: pointer;
    color: #0070bb;
   
    font-size: 11px;
}