.margin_from_both_contact {
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 8%;
  overflow: hidden;
  min-height: 94vh;
}
.margin_both_side_main_conatat_page {
  margin-left: 10%;
  margin-right: 10%;
  overflow: hidden;
}
.contact_div {
  margin-left: 5%;
  margin-right: 5%;
}
.div1_contact {
  width: 100%;
  height: auto;
  border-radius: 8px;

  margin-bottom: 2%;
  background-color: #f2f4f4;
  justify-content: center;
}
.div2_contact {
  width: 100%;
  height: 35vh;
  border-radius: 8px;
  margin-top: 2%;
  margin-bottom: 1%;
  background-color: #f2f4f4;
}
.div3_contact {
  width: 100%;
  height: auto;
  margin-top: 15%;
  background-color: #f2f4f4;
}
.conatctcardHeight {
  height: auto;
  width: 100%;
  border-radius: 8px;
}
.card_MARGIN {
  margin-left: 3% !important;
  padding-top: 3% !important;
  padding-bottom: 3.1% !important;
  margin-right: 3% !important;
}
.heading_for_contactus {
  font-size: 25px;
  font-weight: bold;
}
.schoolinfomargin {
  padding: 3%;
}
.displayinline {
  display: grid;
  /* margin-top: 2%; */
}
.iconcolor {
  color: #0673bd;
  font-size: 20px;
}
.iconscontent {
  font-size: 14px;
}
.conatctcardHeight1 {
  height: auto;
  width: 100%;
  border-radius: 8px;
  padding: 6%;
}
.error_message_color {
  font-size: 10px;
  color: crimson;
}
.conatctcardHeight2 {
  height: auto;
  width: 100%;
  border-radius: 8px;
  padding: 1%;
}

.margin_button_filed {
  margin-top: 2%;
  margin-bottom: 0.5%;
}
.textareaformatting {
  resize: vertical;
}

.input_margin_gap {
  margin-top: -15px;
}
@media only Screen and (min-width: 300px) and (max-width: 650px) {
  .div1_contact {
    width: 100%;
    height: auto;
    border-radius: 8px;

    margin-bottom: 2%;
    background-color: #f2f4f4;
    justify-content: center;
  }

  .div3_contact {
    width: 100%;
    height: auto;
    margin-top: 2%;
    background-color: #f2f4f4;
  }
  .margin_from_both_contact {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 3%;
  }
  .heading_for_contactus {
    font-size: 18px;
    font-weight: bold;
  }
}
