.assignment_result_data_font {
  font-size: 15px;
  font-weight: 700;
}
.font_color_data_assignment {
  color: #97bf47;
}
.card_assignment_result_data {
  display: flex;
  justify-content: space-between;
}
.assignmnt_question_width {
  width: 30%;
}
.heightbtn {
  /* height:50px; */
  padding: 20px;
}
.icon_fonta {
  font-size: 20px;
}

.prevcolor {
  color: whitesmoke !important;
}

.assignemt_Portion_font {
  font-size: 12px;
}
.onHover_cursor {
  cursor: pointer !important;
}
.font_style_question {
  font-size: 12px !important;
  font-weight: 400;
}
#Selected_Card_border {
  border: 1px solid #0070bb !important;
}
#Card_border {
  border: 1px solid lightgray !important;
}
.continueassignment_font label {
  font-weight: bold;
}
.continueassignment_font p {
  font-size: 13px;
  font-weight: 400;
}
.button_right_shift {
  text-align: right;
}
.conitnue_button_color {
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
  outline: none !important;
  text-decoration: none !important;
}
.image_download_font a {
  font-size: 20px;
  cursor: pointer;
}
