.openassignemntcolor {
  color: #0070bb !important;
  cursor: pointer !important;
}
.cursor_hover:hover {
  cursor: pointer;
  color: #0070bb !important;
}
#circle {
  width: 4px;
  height: 4px;
  background: green;
  border-radius: 50%;
}
.timesession_alignright {
  text-align: right;
  margin-top: 2%;
  margin-bottom: 1%;
  cursor: pointer;
  font-size: 12px;
}
.link_color {
  color: #0070bb;
  cursor: pointer;
}

.not_started_cancel {
  color: white !important;
  width: 90% !important;
  text-transform: capitalize !important;
}
.Complete_color_teacher {
  background-color: #97bf47 !important;
  color: white !important;
  width: 90% !important;
  text-transform: capitalize !important;
}
.In_Progress_color_tacher {
  background-color: #fec601 !important;
  color: white !important;
  width: 90% !important;
  text-transform: capitalize !important;
}

@media only screen and (min-width: 320px) and (max-width: 650px) {
  .mobile_font_size {
    font-size: 12px;
  }
  .tabs_class_for_Mobile .MuiTabs-scroller {
    white-space: normal !important;
    text-align: center !important;
  }
  .tabs_class_for_Mobile .MuiTabs-flexContainer {
    display: block !important;
  }
}
.fintTutorsPadding {
  padding: 0px 10px;
}
.tutorsInfo p {
  margin-bottom: 5px;
  font-size: 13px;
}
.findTutorsMarginSapce {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.selecttuotsTime {
  cursor: pointer;
}
.selectTimeBorder .MuiDialog-paperScrollPaper {
  border: 2px solid blue !important;
}
.avaliblityFlex {
  display: flex;
  flex-wrap: wrap;
}
.imageCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.buttonCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.privateStudentMainCard {
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 14px hsl(0deg 0% 75% / 24%);
}
.noDataFoundCenter {
  text-align: center;
}
.noDataFoundCenter {
  color: black;
  font-size: 16px;
}
