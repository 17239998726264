.table_box_shadow {
  box-shadow: none !important;
}
.table_cell_padding {
  padding: 2px !important;
}
.date_time_color {
  color: #0070bb !important;
  font-size: 15px;
}
.text_status_color {
  color: #fec601;
}
.Pannel_padding_hidden .MuiBox-root {
  padding: 0px !important;
}

.myinvitee .MuiTableCell-root {
  line-height: 0.2px;
}
/* .MuiInputBase-input {
  height: 0.1866em !important;
} */
.sheduleCardPAdding {
  padding: 10px !important;
  margin: 10px 5px !important;
}
.schdeuleHeadingFont {
  font-weight: 600;
  font-size: 16;
}
.tableHeadingBold {
  font-weight: 600;
}
