.img_formatting1 {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}
.institutionadminmange {
  cursor: pointer;
  color: #0070bb;
}
.institutionadminmange :hover {
  border-bottom: 1px solid #0070bb;
}
.updatetablecontentcolr {
  color: #4a96ca;
}
.iconsizeupdate {
  font-size: 20px;
  cursor: pointer;
}
.input_border_none:disabled {
  border: none !important;
  background: transparent !important;
  color: gray !important;
}

.input_border_none {
  border: 1px solid lightgray !important;
  color: black !important;
  background: transparent !important;
}
.input_border_none_Color:disabled {
  border: none !important;
  background: transparent !important;
  color: black !important;
}
.card_admin_institutiomanage {
  display: flex;
  justify-content: space-between;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-right: 20%;
  padding-left: 20%;
}
.cardvisible_border {
  border: 1px solid lightgray;
}

.department_color_disabled:disabled {
  color: gray !important;
}
.cardheight_overflow {
  height: 70vh;
  overflow-y: scroll;
}
.cardheightnew_overflow {
  height: 82vh;
  overflow-y: scroll;
}
.content_color {
  color: blue !important;
}
.assignteacher_data_padding {
  padding: 8px !important;
}
.expand_color_drop {
  border-color: 1px solid gainsboro !important;
}
.card_color {
  background-color: #0070bb !important;
  color: white !important;
  text-align: center !important;
  cursor: pointer !important;
}
.Time_Table_Manage_class {
  background-color: #0070bb !important;
}

.icon_cursor_to_edit {
  cursor: pointer !important;
  color: #0070bb !important;
}
.img_main_div_inatituition {
  text-align: center;
}

.assign_t_cell_padding {
  padding: 10px !important;
}
.Card_Active_Border {
  border: 1px solid blue;
}
.Card_Border_None {
  border: 1px solid lightgray;
}
@media (min-width: 600px) {
  .Time_Table_Manage_class .MuiTab-root {
    min-width: 132px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 650px) {
  .img_main_div_inatituition {
    text-align: center;
  }
  .cell_Data_width {
    width: 100%;
  }
}
.current_acdimic_session_flex {
  display: flex;
  align-items: center;
}
.edit_className_flex {
  display: flex;
  justify-content: space-between;
}
