.enquirydetails_background {
  background-color: #f2f4f4;
  height: auto;
  margin-bottom: 10%;
}

.card_margin_both_side {
  margin-left: 2% !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
  margin-right: 2% !important;
}
.card_admissiondetails_height {
  width: 100%;
  height: auto;
}
.enquiry_heading {
  justify-content: space-between;
  display: flex;
}
.Heading_bold {
  font-weight: 600;
  font-size: 20px;
}
.icon_pencil_color {
  color: blue;
}
.icon_font {
  font-size: 20px;
}
.textfiled_margin {
  margin-left: 2%;
  margin-right: 2%;
}
.text_Filed_width {
  width: 100% !important;
}
.inputMarginright {
  margin-right: 3%;
}
.text_filed_heading {
  font-weight: 600;
}
.main_button_div {
  display: flex;
  float: right;
}
.button_enquiry_details {
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
  width: 50%;
}
.gapfromanotherinput {
  margin-top: 2%;
}
.dailog_width_accept {
  width: 80vh;
  margin-bottom: 5%;
  text-align: center;
}
.dailog_ceate_update_timetable {
  width: 80vh;
  margin-bottom: 5%;
}
.dailog_heading {
  font-size: 30px;
  font-weight: 600;
}
.dailog_heading1 {
  font-size: 20px;
  font-weight: 500;
}
.dailog_formatting {
  flex-direction: none;
}
.acceptation_btn {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.add_new_query_btn {
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
}
.button_text_formating {
  text-align: right;
  margin-right: 5%;
}
.search_btn_width {
  width: 45%;
}
.admission_intrrest {
  margin-left: 5%;
  margin-right: 5%;
}
.Waiting {
  background-color: #fec601 !important;
  color: white !important;
  width: 100% !important;
  text-transform: capitalize !important;
}
.Accepted {
  background-color: #97bf47 !important;
  color: white !important;
  width: 100% !important;
  text-transform: capitalize !important;
}
.Rejected {
  background-color: #e91e63 !important;
  color: white !important;
  width: 100% !important;
  text-transform: capitalize !important;
}
.table_foramtitng {
  width: 100%;
  height: auto;
}
.main_full_width {
  width: 100%;
}
@media only Screen and (min-width: 300px) and (max-width: 650px) {
  .admission_intrrest {
    margin-left: 2%;
    margin-right: 2%;
  }
  .button_text_formating {
    text-align: center;
  }
  .enquirydetails_background {
    background-color: #f2f4f4;
    height: auto;
  }
  .gapfromanotherinput {
    margin-top: 6%;
  }
  .dailog_width_accept {
    width: auto;
    margin-bottom: 5%;
    text-align: center;
  }
  .dailog_ceate_update_timetable {
    width: auto;
    margin-bottom: 5%;
  }
  .dailog_heading1 {
    font-size: 18px;
    font-weight: 500;
    margin-left: 15px;
    margin-right: 15px;
  }
  .table_foramtitng {
    width: 100%;
    overflow: scroll;
    height: auto;
  }
  .inputMarginright {
    margin-right: 0%;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1024px) {
  .enquirydetails_background {
    background-color: #f2f4f4;
    height: auto;
  }
  .inputMarginright {
    margin-right: 0%;
  }
}
