.schedule_exam_color {
  background-color: #fec601 !important;
  color: white !important;
  width: 80% !important;
  text-transform: capitalize !important;
}
.MuiTable-root {
  border: 1px solid gainsboro !important;
}
.table_foramtitng .MuiPaper-elevation1 {
  box-shadow: none !important;
}
.dailog_examcancel {
  width: 55vh;
  margin-bottom: 5%;
}
.text_font_size_toconfirm {
  font-size: 19px;
  text-align: center;
}
.text_tranformation_exam {
  text-transform: capitalize !important;
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  width: 100% !important;
}
.personal_dialog_main {
  width: 100%;
  height: auto;
  overflow-x: hidden;
}
/* .main_tab_exam_backgroud_color {
  background-color: #d9e2e9 !important;
  width: 60% !important;
}
.MuiTab-textColorInherit {
  background-color: black !important;

} */
@media only screen and (min-width: 300px) and (max-width: 650px) {
  .dailog_examcancel {
    width: auto;
    margin-bottom: 5%;
  }
  .personal_dialog_main {
    width: 100%;
    height: auto;
    overflow-x: hidden;
    padding: 10% !important;
  }
}

.personal_dialog_main::-webkit-scrollbar {
  width: 7px;
}
.personal_dialog_main::-webkit-scrollbar-thumb {
  background: #888;
}

.completed {
  background-color: "#b0cf74";
}
.pencil_icon_font_size {
  font-size: 15px;
}
.examentermark_flex {
  display: flex;
  justify-content: space-between;
}

.update_mark_container {
  padding: 20px;
}

