.btn_colr_and_width {
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
  width: 100%;
}
.card_content_instition {
  display: flex;
  justify-content: space-between !important;
  padding-top: 1%;
  padding-bottom: 1%;
}

.card_Show_Insitute {
  display: flex;
  justify-content: space-between !important;
  padding-top: 1%;
  padding-bottom: 1%;
}

.institution_Name_font {
  font-size: 15px !important;
  color: black !important;
  margin-top: 3% !important;
  background-color: #fec601 !important;
  font-weight: bold;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.margin_institution_name {
  margin: auto !important;
}
.font_color_holiday {
  text-align: right;
  color: rgb(238, 44, 77);
  padding-top: 7%;
  font-size: 14px;
}
.gobackbutton {
  text-align: right;
  color: #0070bb;
  margin-top: 3%;
  margin-bottom: 1%;
  font-size: 12px;
}
.pageBackButton {
  text-align: right;
  color: #0070bb;
  margin-bottom: 1%;
  font-size: 12px;
}
.manage_edit_cursor {
  cursor: pointer !important;
}
.country_drop_height_width {
  height: 38px !important;
  width: 100% !important;
  border-color: gainsboro !important;
  border-radius: 5px !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}
.font_size_of_icons {
  font-size: 30px;
}
@media only screen and (min-width: 300px) and (max-width: 650px) {
  .font_color_holiday {
    text-align: right;
    color: rgb(238, 44, 77);
    padding-top: 25%;
    font-size: 14px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1024px) {
  .font_color_holiday {
    text-align: right;
    color: rgb(238, 44, 77);
    padding-top: 15%;
    font-size: 14px;
  }
}
.btn_colrWidth {
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
}
