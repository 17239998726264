.button_enquiry_details_upload {
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
  outline: none !important;
  text-decoration: none !important;
}
.bulk_button_right {
  text-align: right;
}
.bulk_upload_flex_data {
  display: flex;
  justify-content: space-between;
}
