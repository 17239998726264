#table_width_assignment {
  min-width: 453px !important;
}
#class_notice_width {
  min-width: 250px !important;
}
.font_size_class_data {
  font-size: 11px !important;
  padding: 2px !important;
}
.table_header_padding {
  padding: 2px !important;
}
.view_notice_font {
  font-size: 10px !important;
}
.asssignment_link_font {
  font-size: 14px;
  cursor: pointer !important;
}
.assigment_data_padding_heading {
  padding: 8px !important;
}
.assigment_data_padding_content {
  padding: 8px !important;
}
#Table_width_Student {
  min-width: 640px !important;
}

/* .mybarwidth{
    width:900px;
} */

/* .studenthomepage .WithStyles\(ForwardRef\(TableCell\)\)-body-162 {
    font-size: 13px;
} */

.studenthomepage .fontsizetable {
  font-size: 13px;
}

.table_assignement_Table_padding {
  padding: 8px !important;
}

.button_width_assignmentTab {
  padding: 0px 0px !important;
}
.button_width_assignmentTab_new {
  padding: 0px 0px !important;
}
.iframe_width {
  width: 100% !important;
  height: 80vh;
}
@media (min-width: 600px) {
  .button_width_assignmentTab {
    min-width: 85px !important;
  }
  .button_width_assignmentTab_new {
    min-width: 40px !important;
  }
}
.usename_onAssignment {
  font-weight: bold;
  font-size: 12px;
}
.assignment_portion_height {
  min-height: 451px;
}
.noticePortion_Height {
  min-height: 219px;
}
.cardbackground_assignment_table {
  background-color: white;
  min-height: 373px;
}
.Notice_background_card {
  background-color: white;
  min-height: 151px;
}
@media only screen and (min-width: 320) and (max-width: 650px) {
  .assignment_portion_height {
    min-height: 471px;
  }
}
.invoice_color_attch {
  cursor: pointer;
  color: #0070bb !important;
}
