.google_map_main_div {
  margin-left: 2%;
  margin-right: 2%;
}
.googlemapcard {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 2%;
  justify-content: center;
}
.contnent_margin_map {
  margin: 3%;
}
.map_card_image {
  height: 10vh;
  width: 10vh;
  border-radius: 50%;
}
.Heading_Google_map_view {
  font-weight: 600;
}
.content_color_grey {
  color: gray;
  font-size: 12px;
}
.Second_portion_map {
  width: 100%;
  height: auto;
  background-color: #f2f4f4;
}
.border_bottom_map {
  border-bottom: 0.6px solid lightgray;
}
.form_changePAssword_width {
  height: auto !important;
  width: 450px;
}
.In_Progress {
  background-color: #fec601 !important;
  color: white !important;
  width: 70% !important;
  text-transform: capitalize !important;
}
.Completed {
  background-color: #97bf47 !important;
  color: white !important;
  width: 70% !important;
  text-transform: capitalize !important;
}
.Not_Started {
  background-color: #e91e63 !important;
  color: white !important;
  width: 70% !important;
  text-transform: capitalize !important;
}
@media only screen and (min-width: 300px) and (max-width: 650px) {
  .Second_portion_map {
    width: 100%;
    height: auto;
    background-color: #f2f4f4;
    margin-top: 5%;
  }
  .form_changePAssword_width {
    height: auto !important;
    width: 370px;
  }
}
