.main_back_color {
  background-image: url("/src//components//Common//SignIn/bbimage.jpg");
  height: 94vh !important;
  width: 100% !important;
  background-size: cover !important;
}
.main_back_color1 {
  background-image: url("/src//components//Common//SignIn/bbimage.jpg");
  height: cover !important;
  width: 100% !important;
  background-size: cover !important;
}

.grid_main_div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.text_field_heading {
  font-size: 12px;
}
.margin_left_right {
  margin-left: 5% !important;
  margin-right: 5% !important;
}
.main_div_Sigin {
  text-align: center !important;
  padding-top: 70px !important;
  width: 400px;
  margin: auto;
}
.form_width {
  height: auto !important;
  width: 400px;
  border-radius: 8px;
  background-color: #f2f4f4 !important;
  opacity: 0.91 !important;
  margin-top: 30px;
}
.main_padding_top_bottom {
  padding-top: 3%;
}
.signupheading {
  padding-top: 5%;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
}
.inputfiledformatting {
  margin-left: 5%;
  margin-right: 5%;
}
.login_button {
  width: 100% !important;
  height: 40px !important;
  background-color: #0070bb !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
}
.button_login_decoration {
  outline: none !important;
  text-decoration: none !important;
}

.termspoilcy {
  color: gray !important;
  font-size: 12px;
  text-align: left !important;
}
.termspoilcy1 {
  color: #539dcf !important;
  font-size: 12px;
  padding-top: 4%;
}
.forgotidPAss {
  justify-content: space-between;
  display: flex;
  font-size: 11px;
  padding-bottom: 2%;
}
.ancor_formatting {
  text-decoration: none !important;
  cursor: pointer;

  color: #0070bb;
}
.ancor_formatting1 {
  text-decoration: none !important;
  cursor: pointer;
  color: #0070bb;
  text-align: center;
  font-size: 11px;
}

.recaptacode {
  margin-top: 2%;
}
.buttonpadding {
  padding-bottom: 3%;
}
.buttonpadding1 {
  padding-bottom: 8%;
  padding-top: 8%;
}
::-webkit-scrollbar {
  width: 7px !important;
}
::-webkit-scrollbar-thumb {
  background: #888 !important;
}
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .grid_main_div {
    width: 100%;
    display: block;
    flex-wrap: wrap;
    box-sizing: border-box;
  }

  .main_div_Sigin {
    text-align: center !important;
    padding-top: 63px !important;
    width: 350px;
    margin: auto;
  }
  .form_width {
    height: auto !important;
    width: 350px;
    border-radius: 8px;
    background-color: #f2f4f4 !important;
    opacity: 0.91 !important;
    margin-top: 30px;
  }
  .signupheading {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
  }
  .margin_left_right {
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .grid_main_div {
    width: 100%;
    display: block;
    flex-wrap: wrap;
    box-sizing: border-box;
  }
  .main_div_Sigin {
    text-align: center !important;
    padding-top: 63px !important;
    width: 350px;
    margin: auto;
  }
  .form_width {
    height: auto !important;
    width: 350px;
    border-radius: 8px;
    background-color: #f2f4f4 !important;
    opacity: 0.91 !important;
    margin-top: 30px;
  }
  .signupheading {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
  }
  .margin_left_right {
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
}

d .MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.terms_padding_top {
  padding-top: 90px;
}
.terms_padding_both_card {
  padding: 0px 5%;
}
.terms_para_font {
  font-size: 14px;
  font-weight: 500;
}
.terms_of_heading_data {
  padding-top: 3%;
}
.terms_of_heading_data h3 {
  color: #0070bb;
}
.terms_of_heading_data ul li {
  padding-left: 10px;
}
.terms_of_heading_data ol li {
  padding-left: 10px;
}
.privacy_strong_padding {
  padding: 10px 4%;
}
.sign_powered_by_font {
  font-size: 10px;
  padding-left: 15%;
}
.sign_powered_by_font img {
  width: 20px;
  height: 20px;
}
.sign_page_color_font {
  cursor: pointer;
  color: #0070bb;
}
.sighn_para_new_font {
  font-size: 10px;
}
