.student_photo_div {
  height: 150px;
  width: 120px;
}
.Reject {
  background-color: #fec601 !important;
  color: white !important;
  width: 50% !important;
  text-transform: capitalize !important;
}
.Employee {
  background-color: #97bf47 !important;
  color: white !important;
  width: 50% !important;
  text-transform: capitalize !important;
}
.Withdrawn {
  background-color: #fec601 !important;
  color: white !important;
  width: 50% !important;
  text-transform: capitalize !important;
}
.Studying {
  background-color: #97bf47 !important;
  color: white !important;
  width: 50% !important;
  text-transform: capitalize !important;
}
.name_outside_border {
  border: 1px solid lightgray;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  border-radius: 5px;
}
.Select_route_transport {
  width: 100%;
  height: auto;
  overflow-x: hidden;
}
@media only screen and (min-width: 300px) and (max-width: 650px) {
  .tableWidth_scroll {
    width: 100%;
    overflow: scroll;
  }
  .Select_route_transport {
    width: 100%;
    height: auto;
    overflow-x: hidden;
    padding: 2%;
  }
}

.Select_route_transport::-webkit-scrollbar {
  width: 7px;
}
.Select_route_transport::-webkit-scrollbar-thumb {
  background: #888;
}

.student_Form_taransport_able_button {
  background-color: lightgray !important;
  text-transform: capitalize !important;
  color: white !important;
}
.student_Form_taransport_able_button_blue {
  background-color: #0070bb !important;
  text-transform: capitalize !important;
  color: white !important;
}
.update_rollno_border {
  border: 1px solid black;
}
.adminstudentlistview {
  font-size: 12px;
}
.idCardImage_width {
  height: 150px !important;
  width: 120px !important;
  border-radius: 10px;
  background: white;
}
.student_id_photo_image {
  height: 150px !important;
  width: 120px !important;
  border-radius: 10px;
}
.student_photo_images {
  height: 80px !important;
  width: 80px !important;
  border-radius: 10px;
}
/* pt techer form css */
.ptFormPadding {
  background: #f3f9ff;
  padding: 60px 6% 60px 6%;
  /* height: calc(100vh - 152px); */
}
.ptfromCardBgColor {
  background-color: #f2f4f4;
  height: auto;
  padding: 10px;
}
.ptFormHeading {
  font-weight: 600;
  font-size: 20px;
}
.ptFomrBorder {
  border: 1px solid lightgray;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  border-radius: 5px;
}
.ptFormlabel {
  font-weight: 600;
}
.selectOption {
  width: 100%;
  height: 36px;
  border-color: #bdbdbd;
  border-radius: 5px;
  padding-left: 10px;
}
.file_back_color {
  background-color: lightgray;
  cursor: pointer;
}
.disabled_button {
  background-color: lightgray !important;
  color: white !important;
  font-size: 14px;
  padding: 10px 30px;
  letter-spacing: 1px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none !important;
  outline: none !important;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  /* box-shadow: 4px 4px 32px -4px rgba(31, 85, 225, 0.45); */
  border-radius: 46px;
}
.iconstoaddfee {
  color: #0070bb;
  font-size: 20px;
}
.addmanageuserfont {
  font-style: 7px;
  color: #0070bb;
  cursor: pointer;
}
#time-picker {
  height: 0.1876em !important;
}

.tableHeadingBold {
  font-weight: 600;
  margin-bottom: 0px;
}
.cardvisible_border {
  border: 1px solid lightgray;
}
.manage_edit_cursor {
  cursor: pointer !important;
}
.paraMarginRemove {
  margin-bottom: 0px;
}
.dailog_formatting {
  flex-direction: none;
}
.cross_font_size {
  font-size: 15px;
}
.EmailPadding {
  padding: 10px 10px 0px 10px;
}
